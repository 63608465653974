<template>
  <div style="width: 100%;">
    <v-form
      id="addCompanyForm"
      ref="addCompanyForm"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent=""
    >
      <v-container class="py-0">
        <v-row class="mb-5">
          <v-col cols="12">
            <h2 class="page-title mb-1">
              {{ $t(`settings.title.${pageMode}`) }}
            </h2>
            <span class="page-step">
              {{ $t(`settings.company.step_${step}`, { step: step }) }}
            </span>
          </v-col>
        </v-row>
        <template v-if="step === 1">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              xl="3"
              class="d-flex align-center"
            >
              <v-text-field
                v-model="modelCompany.okpo"
                id="clientOkpo"
                :rules="rules.okpo"
                :label="$i18n.t('label.okpo')"
                :disabled="isLoading"
                maxlength="10"
                color="privat"
                autofocus
                required
                outlined
                dense
                @keyup.enter="getInfoCompanyByOkpo"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="step === 2">
          <v-row v-if="showAlert.show">
            <v-col>
              <v-alert
                color="blue-grey lighten-5 mb-5"
                :class="showAlert.class"
                :icon="showAlert.icon"
                prominent
              >
                {{ showAlert.msg }}.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <BaseSelectAddress
              v-if="enterManually"
              :validate="needValidate"
              :loading="loading"
              @getAddrId="getAddressId"
              @clearAddrId="clearAddrId"
              @error="errorFromChild"
            />
            <v-col cols="12" sm="8" md="7" lg="6" v-if="!enterManually">
              <v-badge :color="markAddress" dot overlap>
                <v-text-field
                  id="mainOffice"
                  v-model="modelCompany.addressUaText"
                  :label="$t('label.mainOffice')"
                  color="privat"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-badge>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="3"
              lg="2"
              class="pb-2 pb-sm-0 mt-n2 mt-sm-0 mb-2 mb-sm-0"
            >
              <v-btn
                id="enterManually"
                class="mr-sm-4 mb-4 mb-sm-0"
                elevation="1"
                color="blue-grey lighten-5"
                :disabled="loading"
                @click="addressManual"
              >
                <span>
                  {{
                    !enterManually
                      ? $t("action.enterManually")
                      : $t("action.addrFromEkb")
                  }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-text-field
                id="nameOrg"
                v-model="modelCompany.name"
                :label="$t('label.name')"
                :rules="rules.name"
                :disabled="loading"
                maxlength="100"
                minlength="3"
                color="privat"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-text-field
                id="translateOrg"
                v-model="modelCompany.translatedName"
                :label="$t('label.nameLatin')"
                :rules="rules.translatedName"
                :disabled="loading"
                maxlength="40"
                minlength="3"
                color="privat"
                required
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3" class="d-flex">
              <v-text-field
                v-model="modelCompany.ticket"
                :label="$t('label.companyLogoTicket')"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              xl="3"
              class="d-flex justify-space-between"
              :class="{ 'mb-6': $vuetify.breakpoint.xs }"
            >
              <v-btn
                id="addLogo"
                elevation="1"
                color="primary"
                width="47.5%"
                @click="uploadDialog.show = true"
              >
                <span class="white--text" v-if="!modelCompany.ticket">
                  {{ $t("action.add") }}
                </span>
                <span class="white--text" v-else>
                  {{ $t("action.update") }}
                </span>
              </v-btn>
              <v-btn
                v-if="modelCompany.ticket"
                id="deleteLogo"
                elevation="1"
                color="error"
                width="47.5%"
                @click="deleteTicket"
              >
                <span class="white--text">
                  {{ $t("action.delete") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <BaseSelectSupport
                :supports="supports"
                :label="`supportLdap`"
                :loading="loading"
                :validate="needValidate"
                @setSupport="setSupport"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-switch
                v-model="modelCompany.otpRequired"
                id="otpRequired"
                class="mt-0"
                color="privat"
                :disabled="loading"
                :label="$t('label.otpRequired')"
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <p class="subtitle-1 font-weight-medium mb-0">
                {{ $t("settings.company.availablePrograms") }}
              </p>
            </v-col>
          </v-row>
          <v-row class="pt-2 pb-3">
            <v-col cols="12" lg="9" xl="9">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="program in allPrograms"
                  :key="program.id"
                >
                  <v-expansion-panel-header>
                    <v-checkbox
                      v-model="program.isActive"
                      class="detail-checkbox"
                      :label="program.programName"
                      :disabled="loading"
                      hide-details="true"
                      color="privat"
                      @click="changeProgram($event, program)"
                    ></v-checkbox>
                    <template v-slot:actions>
                      <v-btn icon>
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-3 ml-2">
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-switch
                          v-model="program.flIndRate"
                          :id="`${program.id}_flIndRate`"
                          class="mt-0"
                          color="privat"
                          :disabled="loading"
                          :label="$t('label.indRate')"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" md="6" lg="4" xl="3">
                        <v-switch
                          v-model="program.flIndPrComm"
                          :id="`${program.id}_flIndPrComm`"
                          class="mt-0"
                          color="privat"
                          :disabled="loading"
                          :label="$t('label.indCommission')"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row class="mt-3 ml-2">
                      <v-col cols="12">
                        <p class="subtitle-1 font-weight-medium mb-0">
                          {{ $t("settings.company.availableCategories") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="detail in program.leasingObjects"
                      :key="detail.id"
                      class="pt-2 pb-3 ml-2"
                    >
                      <v-col cols="12" lg="4" xl="6">
                        <v-checkbox
                          v-model="detail.active"
                          class="detail-checkbox"
                          :label="detail.nameDesc"
                          :disabled="!program.isActive || loading"
                          hide-details="true"
                          color="privat"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        lg="8"
                        xl="6"
                        v-if="detail.active"
                        class="d-flex justify-start align-center flex-wrap"
                        :class="{
                          'mt-1': vwLess960px,
                          'asd-asd': !detail.active
                        }"
                      >
                        <v-text-field
                          v-model="detail.matrixId"
                          class="detail-matrix"
                          :label="
                            vwLess960px
                              ? $t('label.matrix_md')
                              : $t('label.matrix')
                          "
                          :rules="rules.matrix"
                          :required="detail.active"
                          :disabled="loading"
                          maxlength="1"
                          hide-details="true"
                          outlined
                          dense
                        >
                          {{ detail.matrixId }}
                        </v-text-field>
                        <v-text-field
                          v-model="detail.matrixIdComp"
                          class="detail-matrix ml-2"
                          :label="
                            vwLess960px
                              ? $t('label.matrixComp_md')
                              : $t('label.matrixComp')
                          "
                          :rules="rules.matrix"
                          :required="detail.active"
                          :disabled="loading"
                          maxlength="1"
                          hide-details="true"
                          outlined
                          dense
                        >
                          {{ detail.matrixIdComp }}
                        </v-text-field>
                        <v-text-field
                          v-model="detail.matrixIdComp579"
                          class="detail-matrix ml-2"
                          :label="
                            vwLess960px
                              ? $t('label.matrixComp579_md')
                              : $t('label.matrixComp579')
                          "
                          :rules="rules.matrix"
                          :required="detail.active"
                          :disabled="loading"
                          maxlength="1"
                          hide-details="true"
                          outlined
                          dense
                        >
                          {{ detail.matrixIdComp579 }}
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="10" lg="8">
              <v-textarea
                v-model="modelCompany.comment"
                :label="
                  modelCompany.comment
                    ? $t('label.comment')
                    : `${$t('label.comment')} ${$t('label.notRequired')}`
                "
                :disabled="loading"
                hide-details="true"
                rows="2"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex justify-space-between flex-column flex-sm-row my-6"
          >
            <v-btn
              id="cancel"
              class="mr-sm-4 mb-4 mb-sm-0"
              elevation="1"
              color="blue-grey lighten-5"
              :disabled="loading"
              @click="
                pageId === 'add' && step === 2 ? (step = 1) : $router.go(-1)
              "
            >
              {{ textBtnCancel }}
            </v-btn>
            <v-btn
              id="continue"
              elevation="1"
              color="privat"
              :loading="loading"
              :disabled="disabledBtn"
              @click="step === 1 ? getInfoCompanyByOkpo() : sendCompany()"
            >
              <span class="white--text">
                {{ textBtnContinue }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <base-snackbar :props="snack" :show="show" @close="show = false" />
      </v-container>
    </v-form>

    <v-dialog
      class="uploadDialog"
      v-model="uploadDialog.show"
      :width="uploadDialog.width"
      persistent
    >
      <v-card class="pa-3">
        <v-card-title class="pa-2 d-flex justify-space-between">
          {{ $t(`confirm.upload.title`) }}
          <v-btn
            v-if="uploadDialog.closeBtn"
            @click="closeUploadDialog"
            class="close-dialog mt-n4 mr-n2"
            :ripple="false"
            small
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-2 pb-0">
          <v-file-input
            v-model="companyLogo"
            :label="$t('label.companyLogo')"
            :rules="companyLogo ? rules.logo : []"
            :disabled="loaderLogo"
            outlined
            dense
            accept="image/*"
            :truncateLength="$vuetify.breakpoint.sm ? 15 : 25"
            @change="changeCompanyLogo"
          />
          <span
            class="ml-8 mb-4 d-block"
            v-html="$t(`settings.company.paramsLogo`)"
          ></span>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            id="cancelUpload"
            elevation="0"
            width="37%"
            color="blue-grey lighten-5"
            :disabled="loaderLogo"
            @click="closeUploadDialog"
          >
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn
            id="upload"
            elevation="0"
            width="57%"
            color="privat"
            :disabled="disabledBtnUpload"
            :loading="loaderLogo"
            @click="uploadLogo"
          >
            <span class="white--text">{{ $t("action.upload") }}</span>
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :size="22"
                :width="2"
                :value="75"
                color="white"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  interactionDataAddress,
  searchCompanyById,
  searchCompanyByOkpo
} from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import BaseSelectAddress from "@/components/BaseSelectAddress";
import BaseSelectSupport from "@/components/BaseSelectSupport";
import CONST from "@/utils/constants";
import createMockData from "@/mock/mockNewData";
import { sendLogo } from "@/services/orders.service";
// import { cloneDeep } from "lodash-es";

export default {
  name: "SettingsCompany",
  mixins: [showSnack],
  props: {
    options: Object,
    company: Object,
    loading: Boolean
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      pageMode: "company_add",
      step: 1,
      modelCompany: {
        okpo: null, // "39104926"
        addressId: null,
        addressUaText: null,
        markAddress: "transparent",
        name: null,
        translatedName: null,
        supportLdap: null,
        otpRequired: false,
        comment: null,
        ticket: null
      },
      showAlert: {
        show: false,
        icon: "mdi-bank",
        class: "",
        msg: ""
      },
      enterManually: false,
      addressIdFromEkb: false,
      supports: [],
      needValidate: false,
      leasingObjects: [],
      panel: [],
      allPrograms: [],
      companyLogo: null,
      loaderLogo: false,
      errorLogo: false,
      uploadDialog: {
        show: false,
        width: 600,
        closeBtn: true,
        type: "upload",
        msg: "",
        action: "upload"
      }
    };
  },
  components: {
    BaseSelectAddress,
    BaseSelectSupport
  },
  computed: {
    ...mapGetters(["getAllOrg", "getPointOfEntry", "devEnv"]),
    textBtnCancel() {
      let text = this.$i18n.t("action.cancel");

      if (this.pageId === "edit" && this.step === 2) {
        text = this.$i18n.t("action.cancel");
      }
      if (this.pageId !== "edit" && this.step === 2) {
        text = this.$i18n.t("action.back");
      }

      return text;
    },
    textBtnContinue() {
      let text = this.$i18n.t("action.continue");

      if (this.pageId === "add" && this.step === 2) {
        text = this.$i18n.t("action.add");
      }
      if (this.pageId === "edit" && this.step === 2) {
        text = this.$i18n.t("action.save");
      }

      return text;
    },
    pageId() {
      return this.$route.params.id;
    },
    getOrgId() {
      return this.$route.query.orgId;
    },
    orgName() {
      console.log("computed orgName", this.company);
      return this.company?.name || this.selectedCompany?.name;
    },
    selectedCompany() {
      console.log("computed selectedCompany", this.options);
      return this.options.companies.find(el => el.id === +this.getOrgId);
    },
    rules() {
      return {
        okpo: [
          v => !!v || this.$i18n.t("rules.required"),
          v => CONST.REG_EXP_OPKO.test(v) || this.$i18n.t("rules.okpo.correct")
        ],
        name: [
          v => !!v || this.$i18n.t("rules.required"),
          v => CONST.MIN_MAX_NAME.test(v) || this.$i18n.t("rules.MIN_MAX_NAME")
        ],
        translatedName: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            CONST.MIN_MAX_TRANSLATE_NAME.test(v) ||
            this.$i18n.t("rules.MIN_MAX_TRANSLATE_NAME")
        ],
        supportLdap: [v => !!v || this.$i18n.t("rules.required")],
        matrix: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /[1-9]/.test(v) || this.$i18n.t("rules.isNotNumber")
        ],
        logo: [
          v =>
            v.size <= CONST.MAX_FILE_SIZE ||
            this.$i18n.t("rules.logo.max_size"),
          v => v.type === "image/png" || this.$i18n.t("rules.logo.type_file")
        ]
      };
    },
    markAddress() {
      return this.modelCompany.markAddress.toLowerCase() || "transparent";
    },
    disabledBtn() {
      return this.showAlert.show || this.loading;
    },
    vwLess960px() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.sm
      );
    },
    disabledBtnUpload() {
      return !this.companyLogo || this.errorLogo;
    },
    styleBtnUploadLogo() {
      if (this.$vuetify.breakpoint.xs) {
        return { width: "100%", marginBottom: "24px" };
      }

      return { width: "50%", minWidth: "190px" };
    }
  },
  methods: {
    addressManual() {
      this.enterManually = !this.enterManually;
      if (!this.enterManually) {
        this.showAlert.show = false;
        this.modelCompany.addressId = this.addressIdFromEkb;
      } else {
        this.modelCompany.addressId = null;
      }
    },
    initAlert(data) {
      let addrType = data.foundAddress.addrType;

      switch (addrType) {
        case "ATO":
          this.showAlertBadAddress();
          break;
        case "ConflictLine":
          this.showAlertBadAddress();
          break;
        case "Crimea":
          this.showAlertBadAddress();
          break;
      }
    },
    showAlertBadAddress() {
      this.showAlert.class = "ERROR";
      this.showAlert.msg = this.$i18n.t("settings.alert.badAddress");
      this.showAlert.icon = "mdi-alert-circle";
      this.showAlert.show = true;
    },
    getInfoCompanyByOkpo() {
      if (this.devEnv) {
        const data = createMockData("newOrg");

        this.setDefaultParamsCompany(data);
        this.initAlert(data);
        this.step = 2;
        // mock
        return;
      }

      if (this.$refs.addCompanyForm.validate()) {
        this.isLoading = true;
        searchCompanyByOkpo(this.modelCompany.okpo)
          .then(res => {
            let data = res.data;

            if (data) {
              this.setDefaultParamsCompany(data);
              this.initAlert(data);
              this.step = 2;
            } else {
              let errorCode = errorHandler({}, "GET_DATA");

              this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
              console.log("then getInfoCompanyByOkpo");
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "GET_DATA");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getInfoCompanyByOkpo");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefaultParamsCompany(data) {
      let foundAddress = data.foundAddress;

      this.modelCompany = Object.assign(this.modelCompany, data);
      this.allPrograms = data.programSettings?.map((el, idx) => {
        const program = el.program.value;

        return {
          ...el,
          id: idx,
          programName: this.$i18n.t(`offer.allTypeFinancing.${program}`)
        };
      });
      if (!foundAddress.addressUaText) {
        this.addressManual();
      } else {
        this.addressIdFromEkb = foundAddress.addressId;
        this.modelCompany.addressId = foundAddress.addressId;
        this.modelCompany.addressUaText = foundAddress.addressUaText;
        this.modelCompany.markAddress = foundAddress.color;
      }
      if (data.managerLdap && data.managerFio) {
        this.createSupports(data);
      }
    },
    validateBasicProps(model) {
      const isSupport = model.supportLdap;
      const isAddrId = model.addressId;
      const isName = model.name;
      const isTranslated = model.translatedName;

      if (!isAddrId || !isName || !isTranslated || !isSupport) {
        this.$refs.addCompanyForm.validate();
        this.showSnack("error", [this.$i18n.t(`error.INVALID_FORM_FIELD`)]);

        return false;
      }

      return true;
    },
    validateProgramsAndCategories() {
      for (let i = 0; this.allPrograms.length > i; i++) {
        let program = this.allPrograms[i];

        if (program.isActive) {
          let checkedCategory = 0;

          program.activeCategory = [];
          for (let x = 0; program.leasingObjects.length > x; x++) {
            let category = program.leasingObjects[x];

            if (category.active) {
              checkedCategory++;
              let validCategory = !!(
                category.matrixId &&
                category.matrixIdComp &&
                category.matrixIdComp579
              );

              if (!validCategory) {
                this.$refs.addCompanyForm.validate();
                this.showSnack("error", [
                  this.$i18n.t(`error.ORG_EMPTY_OBJ_TYPES`)
                ]);
                this.panel.push(program.id);

                return false;
              }
              program.activeCategory.push(category);
            }
          }

          if (!checkedCategory) {
            this.showSnack("error", [this.$i18n.t(`error.EMPTY_PROGRAM`)]);
            this.panel.push(program.id);

            return false;
          }
        } else {
          program.activeCategory = [];
        }
      }

      return true;
    },
    createSendCompany(company) {
      const programs = this.allPrograms.map(el => {
        const active = el.activeCategory;
        const program = el.program.value;

        return {
          ...el,
          leasingObjects: active,
          program: program
        };
      });

      return {
        okpo: company.okpo,
        addressId: company.addressId,
        name: company.name,
        translatedName: company.translatedName,
        supportLdap: company.supportLdap,
        otpRequired: company.otpRequired,
        comment: company.comment,
        id: company.id,
        programSettings: programs.filter(el => el.isActive),
        ticket: company.ticket
      };
    },
    sendCompany() {
      this.needValidate = !this.needValidate;

      if (!this.validateBasicProps(this.modelCompany)) {
        return;
      }
      if (!this.validateProgramsAndCategories()) {
        return;
      }

      const sendData = this.createSendCompany(this.modelCompany);

      this.pageId !== "edit"
        ? this.$emit("event", ["addCompany", sendData])
        : this.$emit("event", ["updateCompany", sendData]);
    },
    createUrlAddress(modelAd) {
      let corpus = modelAd.corpus ? `&corpus=${modelAd.corpus}` : "";
      let flat = modelAd.flat ? `&flat=${modelAd.flat}` : "";
      let idStreet = modelAd.street.code;
      let building = modelAd.building.desc;

      return `esa/addr/updateWCheck?id=${idStreet}&house=${building}${corpus}${flat}`;
    },
    getAddressId(modelAd) {
      let url = this.createUrlAddress(modelAd);

      this.isLoading = true;
      interactionDataAddress(url, "POST")
        .then(res => {
          this.modelCompany.addressId = res.data;
          this.showAlert.show = false;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          if (errorCode === "ADDR_RESTRICTION") {
            this.showAlertBadAddress();
          } else {
            this.showAlert.show = false;
            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          }
          console.log("catch getAddressId");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearAddrId() {
      this.modelCompany.addressId = null;
    },
    errorFromChild(error) {
      this.showSnack("error", error);
    },
    setSupport(val) {
      this.modelCompany.supportLdap = val.id;
    },
    getInfoCompanyById() {
      if (this.devEnv) {
        let data = createMockData("org");

        this.modelCompany = Object.assign(this.modelCompany, data);
        this.addressIdFromEkb = data.addressId;
        this.modelCompany.addressId = data.addressId;
        this.modelCompany.addressUaText = data.address;
        this.allPrograms = data.programSettings.map((el, idx) => {
          const program = el.program.value;

          return {
            ...el,
            id: idx,
            programName: this.$i18n.t(`offer.allTypeFinancing.${program}`)
          };
        });
        if (data.managerLdap && data.managerFio) {
          this.createSupports(data);
        }
        // mock
        return;
      }

      searchCompanyById(+this.getOrgId)
        .then(res => {
          let data = res.data;

          this.modelCompany = Object.assign(this.modelCompany, data);
          this.addressIdFromEkb = data.addressId;
          this.modelCompany.addressId = data.addressId;
          this.modelCompany.addressUaText = data.address;
          this.allPrograms = data.programSettings.map((el, idx) => {
            const program = el.program.value;

            return {
              ...el,
              id: idx,
              programName: this.$i18n.t(`offer.allTypeFinancing.${program}`)
            };
          });
          if (data.managerLdap && data.managerFio) {
            this.createSupports(data);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "GET_DATA");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getInfoCompany");
        })
        .finally(() => {});
    },
    createSupports(data) {
      let arrFio = data.managerFio.split(" ");

      let support = {
        surname: arrFio[0],
        firstName: arrFio[1],
        patronymic: arrFio[2],
        id: data.managerLdap,
        label: `${arrFio[0]} ${arrFio[1]} ${arrFio[2]}`,
        isTouched: false
      };
      this.supports.push(support);
      this.setSupport(support);
    },
    changeProgram(ev, pr) {
      ev.stopPropagation();
      ev.preventDefault();

      if (!pr.isActive) {
        this.panel = [];
        this.$refs.addCompanyForm.resetValidation();
      } else {
        this.panel.push(pr.id);
      }
    },
    changeCompanyLogo() {
      this.errorLogo = false;

      if (this.companyLogo) {
        const size = this.companyLogo.size;
        const type = this.companyLogo.type;

        if (size > CONST.MAX_FILE_SIZE) this.errorLogo = true;
        if (type !== "image/png") this.errorLogo = true;
      }
    },
    closeUploadDialog() {
      this.uploadDialog.show = false;
      this.companyLogo = null;
      this.errorLogo = false;
    },
    uploadLogo() {
      let formData = new FormData();
      const params = `ekbId=${this.modelCompany.ekbId}&okpo=${this.modelCompany.okpo}`;

      this.loaderLogo = true;
      formData.append("file", this.companyLogo);

      sendLogo(params, formData)
        .then(res => {
          this.showSnack("success", [this.$i18n.t("success.uploadLogo")]);
          console.log("sendLogo", res.data);
          this.modelCompany.ticket = res.data;
          this.loaderLogo = false;
          this.closeUploadDialog();
        })
        .catch(err => {
          let errorCode = errorHandler(err, "uploadFile");

          this.showSnack("error", [this.$i18n.t(`error.uploadFile`)]);
          this.loaderLogo = false;
          console.log("catch sendLogo", errorCode);
        });
    },
    deleteTicket() {
      this.modelCompany.ticket = null;
      this.showSnack("warning", [this.$i18n.t("warning.deleteLogo")]);
    }
  },
  mounted() {
    if (this.pageId === "edit") {
      this.pageMode = "company_edit";
      this.getInfoCompanyById();
      this.step = 2;
      this.$emit("event", ["getEsaDefaultSettings", false]);
    }
  }
};
</script>

<style lang="scss">
#addCompanyForm {
  width: 100%;
  .container {
    max-width: 100%;
  }
  [class*="col"] {
    padding: 2px 12px;
  }
  .v-badge {
    width: 100%;
  }
  .v-btn {
    height: 39px;
  }
  .v-alert {
    max-width: 650px;
    font-size: 15px;
    .v-alert__icon {
      height: 32px;
      min-width: 32px;
      margin-left: 10px;
      margin-right: 22px;
      &.v-icon {
        font-size: 24px;
      }
      &::before {
        z-index: 9999;
        color: #fff;
        margin-left: 1px;
        margin-bottom: 1px;
      }
      &::after {
        background-color: #addfb4 !important;
        opacity: 1 !important;
      }
    }
    &--prominent.ERROR {
      .v-alert__icon {
        &.v-icon {
          font-size: 45px;
        }
        &::before {
          color: #d32f2f;
        }
        &::after {
          background-color: #fff !important;
        }
      }
    }
  }
  .v-badge--dot .v-badge__badge {
    width: 12px;
    height: 12px;
    inset: -2px -2px auto auto !important;
  }
  #cancel {
    width: 37%;
  }
  #continue {
    width: 57%;
    &.w-100 {
      width: 100%;
    }
  }
  #enterManually {
    width: 100%;
    min-width: 175px;
  }
  #cancel,
  #continue,
  #enterManually {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .detail-wrap {
  }
  .detail-checkbox {
    &.v-input--selection-controls {
      flex: 1 1 auto;
      margin-top: 0;
      padding-top: 0;
    }
    .v-input__slot {
      margin: 6px 0;
    }
    .v-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .detail-matrix {
    width: 135px;
    max-width: 200px;
    .v-input__control {
      align-items: flex-end;
    }
    .v-input__slot {
      min-height: 30px;
    }
    input {
      max-height: 30px;
    }
    .v-label {
      top: 5px;
      font-size: 14px;
    }
    &.v-input--is-focused .v-label,
    &.v-input--is-dirty .v-label {
      top: 9px;
    }
  }
  .row + hr {
    margin-top: 12px;
  }
  hr + .row {
    margin-top: 24px;
  }
  .v-expansion-panel {
    margin-bottom: 16px;
    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.07);
    }
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
  .v-expansion-panel--active:not(:first-child)::after,
  .v-expansion-panel--active + .v-expansion-panel::after {
    opacity: 1;
  }
  .v-expansion-panel-header {
    &:hover {
      cursor: default;
    }
    &:not(.v-expansion-panel-header--mousedown):focus::before {
      opacity: 0;
    }
    .detail-checkbox .v-input__slot {
      @media (max-width: 760px) {
        width: 50%;
      }
      @media (max-width: 660px) {
        width: 50%;
      }
      width: 40%;
    }
  }
  .v-input--switch label {
    margin-left: 12px;
  }
}
</style>
